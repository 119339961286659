<template>
  <div class="card">
    <div class="card-header m-0 px-2 py-3" style="padding: 0.75">
      <h6 class="m-0" data-testid="team-page.create-new-user.section.heading">Create New User</h6>
    </div>
    <form
      ref="form"
      novalidate
      class="card-body px-2 py-3"
      data-testid="team-page.create-new-user.form"
      @submit="submit"
    >
      <div class="row g-3 mb-3">
        <div class="col">
          <VueMultiSelectSearch
            id="email-vue-multiselect-search"
            v-model="emailModel"
            :input-group="false"
            placeholder="Add an email"
            name="Email Address"
            :options="emailOptions"
            :max-height="400"
            :multiple="true"
            :taggable="true"
            tag-placeholder="Press enter to add a email"
            :error="errorMessages.get('email')"
            :server-error="serverError"
            data-testid="team-page.create-new-user.form.input-container.emails"
            @tag="addEmailTag"
          />
        </div>
        <div class="col">
          <VueMultiSelectSearch
            id="customer-vue-multiselect-search"
            v-model="customerModel"
            input-group
            :loading="customerLoading"
            name="Customer Membership"
            placeholder="Select or search for a customer"
            :options="customerOptions"
            track-by="name"
            label="name"
            :max-height="400"
            :close-on-select="false"
            :multiple="true"
            required
            :error="errorMessages.get('customers')"
            :server-error="serverError"
            data-testid="team-page.create-new-user.form.input-container.customers"
            @search="(value: string) => searchCustomers(value)"
          >
            <template #noResult>
              <div class="d-flex flex-column">
                <span class="mb-2">No customers found.</span>
                <span>Please try again using the search button.</span>
              </div>
            </template>
          </VueMultiSelectSearch>
        </div>
        <div class="col">
          <VueMultiSelectSearch
            id="group-vue-multiselect-search"
            v-model="groupModel"
            :input-group="false"
            name="Group Membership"
            placeholder="Select a group"
            track-by="name"
            label="name"
            :options="groupOptions"
            :max-height="400"
            :close-on-select="false"
            :multiple="true"
            required
            :error="errorMessages.get('groups')"
            :server-error="serverError"
            data-testid="team-page.create-new-user.form.input-container.groups"
          >
            <template #option="{option}">
              {{ option.name }}
              <span v-if="option.allowed_domains !== '' && option.allowed_domains !== null">
                ({{ option.allowed_domains }} only)
              </span>
            </template>
          </VueMultiSelectSearch>
        </div>
        <div class="col">
          <VueMultiSelectSearch
            id="role-vue-multiselect-search"
            v-model="roleModel"
            :input-group="false"
            label="value"
            placeholder="Select a role"
            track-by="value"
            name="User Role For Customer"
            :options="roleOptions"
            :max-height="400"
            required
            :error="errorMessages.get('role')"
            :server-error="serverError"
            data-testid="team-page.create-new-user.form.input-container.role"
          />
        </div>
      </div>
      <BootstrapButton
        name="submit-button"
        class="me-2"
        type="submit"
        :disabled="formLoading"
        :loading="formLoading"
        data-testid="team-page.create-new-user.form.button.submit"
        @click.stop
      >
        Invite
      </BootstrapButton>
    </form>
  </div>
</template>
<script lang="ts" setup>
import {emailGetDomain} from '@/sphere/email';

/*
 * Email
 */
const emailOptions = ref<string[]>([]);
const emailModel = ref<string[]>([]);
const addEmailTag = (email: string) => {
  if (!emailModel.value.includes(email)) emailModel.value.push(email);
};

/*
 * Customers
 */
const customersStore = useCustomersStore();
const customerOptions = computed(() => customersStore.customers || []);
const customerModel = ref<Customer[]>([]);
const customerLoading = ref(false);
const searchCustomers = async (value: string) => {
  customerLoading.value = true;
  try {
    // Fetch customers using search text
    const customers: Customer[] = await customersStore.readCustomers(value);
    // Merge results with options model(i.e customersStore.customers)
    const duplicateCustomers: Customer[] = [...customerOptions.value, ...customers];
    // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
    const uniqueCustomers: Customer[] = duplicateCustomers.filter(
      (e: Customer, i: number, a: Customer[]) =>
        i === a.findIndex((t: Customer) => t.id === e.id && t.name === e.name), // element, index, array
    );
    // Set the new de-duplicated customers
    await customersStore.setCustomers(uniqueCustomers);
  } catch (error) {
    console.log(error);
  } finally {
    customerLoading.value = false;
  }
};

/*
 * Groups
 */
const userStore = useUserStore();
const groupOptions = computed(() => userStore.user?.groups || []);
const groupModel = ref<UserGroup[]>([]);

/*
 * Role
 */
const teamStore = useTeamStore();
const roleOptions = computed(() => teamStore.roles || []);
const roleModel = ref();

/**
 * Form
 */
const {axiosApiInstance} = useApiInstance();
const form = ref<HTMLFormElement>();
const formLoading = ref(false);
const defaultErrorMessages = ref(
  new Map([
    ['email', ''],
    ['customers', ''],
    ['groups', ''],
    ['role', ''],
  ]),
);
const errorMessages = ref(defaultErrorMessages.value);
// Used to tell the input not to use client-side validation
const serverError = ref(false);

const submitInvite = async () => {
  try {
    formLoading.value = true;

    await Promise.all(
      emailModel.value.map(async (email) => {
        // const role = activeInvitation.value.customers[0]?.role || 'em';
        await axiosApiInstance({
          method: 'post',
          url: 'invitations/',
          data: {
            email: email,
            customers: customerModel.value,
            groups: groupModel.value,
            role: roleModel.value.name,
            sending_domain: window.location.origin,
          },
        });
      }),
    );
    emailModel.value = [];
    customerModel.value = [];
    groupModel.value = [];
    roleModel.value = undefined;
    await teamStore.fetchInvitations();
    serverError.value = false;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage: string | object = error.response?.data ?? 'Unknown error';
      serverError.value = true;
      // Remove .was-validated for server-side validation response.
      if (form.value) form.value.classList.remove('was-validated');
      if (typeof errorMessage !== 'string') {
        console.log(errorMessage);
        for (const [key, value] of Object.entries(errorMessage)) {
          errorMessages.value.set(key, value[0]);
        }
      }
    }
  } finally {
    formLoading.value = false;
  }
};

/*
 * Bootstrap client validation is incompatible w/ vue-multiselect
 * Have to manually validate multiselects
 */
const submit = async (event: Event) => {
  let isValid = true;
  errorMessages.value.clear();
  errorMessages.value = defaultErrorMessages.value;

  //deduplicate emails
  emailModel.value = [...new Set(emailModel.value)];

  // Required
  if (emailModel.value.length === 0) errorMessages.value.set('email', 'Email is required');
  if (customerModel.value.length === 0)
    errorMessages.value.set('customers', 'Customer is required');
  if (groupModel.value.length === 0) errorMessages.value.set('groups', 'Group is required');
  if (roleModel.value === undefined) errorMessages.value.set('role', 'Role is required');

  // Email
  const validateEmail = (email: string): RegExpMatchArray | null =>
    email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  for (const e of emailModel.value) {
    if (validateEmail(e) === null) errorMessages.value.set('email', 'Enter a valid email address.');
  }

  // Groups
  for (const g of groupModel.value) {
    const emailDomains = emailModel.value.map((e: string) => emailGetDomain(e));
    if (g.allowed_domains !== null && g.allowed_domains !== '') {
      if (emailDomains.some((e) => !g.allowed_domains.includes(e)) === true)
        errorMessages.value.set('groups', 'Selected roles are invalid for email address');
    }
  }

  // Check for error messages
  for (const m of errorMessages.value.values()) {
    if (m.length > 0) isValid = false;
  }

  if (isValid === false) {
    event.preventDefault();
    event.stopPropagation();
    if (form.value) form.value.classList.add('was-validated');
  } else {
    event.preventDefault();
    await submitInvite();
  }
};
</script>

<template>
  <MiscListGroup>
    <MiscListGroupItemButton
      id="setting-menu-change-language"
      icon="flag"
      :title="translateText('Language')"
      @click="onChangeLanguage()"
    >
      <template #content-right>
        <ImageSwitch
          id="lang-switch"
          :text="props.lang === 'en-US' ? 'NL' : 'EN'"
          data-testid="sidebar.expanded.foooter.language.switch"
          :checked="checkedLangSwitch(props.lang)"
          :disabled="true"
        >
          <span> {{ `${props.lang === 'en-US' ? 'EN' : 'NL'}` }} </span>
        </ImageSwitch>
      </template>
    </MiscListGroupItemButton>
    <MiscListGroupItemButton
      id="setting-menu-change-theme"
      icon="moon"
      :title="translateText('Dark Mode')"
      @click="onChangeTheme()"
    >
      <template #content-right>
        <ImageSwitch
          id="theme-switch"
          data-testid="sidebar.expanded.foooter.theme.switch"
          :checked="checkedThemeSwitch(props.theme)"
          :disabled="true"
        >
          <img :src="themeImage" alt="Theme" style="width: 1.75rem; height: 1.75rem" />
        </ImageSwitch>
      </template>
    </MiscListGroupItemButton>
    <MiscListGroupItemButton
      id="menu-help-and-feedback"
      icon="edit-3"
      :title="translateText('Help & Feedback')"
      @click="$emit('toggleFeedback')"
    />
  </MiscListGroup>
</template>

<script lang="ts" setup>
import translate from '@/translate';
import sun_toggle from '@/assets/images/profile-menu/sun_toggle.svg';
import moon_toggle from '@/assets/images/profile-menu/moon_toggle.svg';

interface Props {
  lang: string;
  theme: string;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  toggleFeedback: [];
  toggleTheme: [value: string];
  toggleLanguage: [value: string];
}>();

const themeImage = computed(() => (props.theme === 'dark-only' ? moon_toggle : sun_toggle));

function translateText(input: string) {
  return translate.translate(props.lang, input);
}

const onChangeLanguage = () => {
  if (props.lang === 'en-US') {
    emits('toggleLanguage', 'nl-NL');
  } else {
    emits('toggleLanguage', 'en-US');
  }
};

const checkedLangSwitch = (language: string) => {
  return language === 'en-US' ? true : false;
};

const onChangeTheme = () => {
  if (props.theme === 'dark-only') {
    emits('toggleTheme', 'light-only');
  } else {
    emits('toggleTheme', 'dark-only');
  }
};

const checkedThemeSwitch = (theme: string) => {
  return theme === 'light-only' ? false : true;
};
</script>

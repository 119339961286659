<template>
  <div>
    <ul class="nav nav-underline">
      <li v-for="item in items" :key="item.title" class="nav-item">
        <router-link
          v-if="activeRoute.path"
          :to="item.href || ''"
          :class="item.disabled === true || !item.href ? 'disabled-link' : ''"
        >
          <a
            class="nav-link"
            :class="{
              active: path === item.href && item.href,
              disabled: item.disabled === true || !item.href,
            }"
          >
            {{ capitalize(item.title) }}
          </a>
        </router-link>
        <a
          v-else
          class="nav-link"
          style="font-size: 1rem"
          :class="{active: activeRoute.title === item.title}"
          :style="{cursor: activeRoute.title !== item.title ? 'pointer' : ''}"
          @click="$emit('update:activeRoute', item)"
        >
          {{ capitalize(item.title) }}
        </a>
      </li>
    </ul>
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  items?: {title: string; href?: string; disabled?: boolean}[];
  activeRoute: {path?: string; title?: string};
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
});

defineEmits(['update:activeRoute']);

const path = computed(() => props.activeRoute.path || '');

const capitalize = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
</script>

<style lang="scss" scoped>
.disabled-link {
  pointer-events: none;
}

.nav-link {
  font-size: 1.25rem;
}

.nav-link.active {
  background-image: linear-gradient(to right, #035bff, #0bf6f2);
  background-position: 0% 100%;
  background-size: 100% 3px;
  background-repeat: no-repeat;
  border-bottom: 0px;
}

[data-bs-theme='dark'] .nav-link.active {
  background-image: linear-gradient(to right, var(--bs-body-bg), white);
  background-position: 0% 100%;
  background-size: 100% 3px;
  background-repeat: no-repeat;
  border-bottom: 0px;
}
</style>

import {TreeNodePage, Page, TreeNodeCore} from '@/stores/apolloPlatform/cores/portalNavigation';
import {RouteRecordRaw} from 'vue-router';

// e.g `Microsoft Azure` to `microsoft_azure`
const pathFromTitle = (title: string) => title.replaceAll(' ', '_').toLowerCase();

function pageRouteWithPath(
  pageNode: TreeNodePage,
  pathGetter: (p: Page) => string,
  routeNameSuffix: string,
): RouteRecordRaw {
  const path = pathGetter(pageNode.page);
  if (pageNode.page.class_name === 'Category') {
    return {
      name: `page-${pageNode.page.id}-${routeNameSuffix}`,
      path: path,
      children: pageNode.children.map((child) =>
        pageRouteWithPath(child, pathGetter, routeNameSuffix),
      ),
    };
  } else if (pageNode.page.class_name === 'Page') {
    const pageRoute: RouteRecordRaw = {
      path: path,
      children: [
        {
          name: `page-${pageNode.page.id}-${routeNameSuffix}`,
          path: '',
          component: () => import('@/components/cores/PageView.vue'),
          props: () => ({
            activePage: pageNode.page,
          }),
        },
      ],
      meta: {
        activePageNode: pageNode,
      },
    };

    // Some embeddings have special routing rules

    if (pageNode.page.embeddings.length === 1) {
      const embedding = pageNode.page.embeddings[0];
      const name = embedding.class_name;
      const type = embedding.content.type;
      if (name === 'ComponentEmbedding' && type === 'product_catalogue') {
        pageRoute.children.push({
          path: 'update/:id?',
          alias: 'create/:id?',
          meta: {subpage: 'ProductCatalogueForm'},
          component: () => import('@/components/cores/PageView.vue'),
          props: () => ({
            activePage: pageNode.page,
          }),
        });
      }
    }

    return pageRoute;
  }

  throw Error("Page class_name not 'Category' or 'Page'");
}

/// Makes page-ID-en and page-ID-nl named routes
function createLanguagePageRoutes(node: TreeNodePage): RouteRecordRaw[] {
  return [
    pageRouteWithPath(node, (p) => pathFromTitle(p.title_en ?? p.title), 'en'),
    pageRouteWithPath(node, (p) => pathFromTitle(p.title_nl ?? p.title_en ?? p.title), 'nl'),
  ];
}

/// Makes core-<slug> named routes with page-ID-en and page-ID-nl child routes.
export function createCoreRoute(coreNode: TreeNodeCore): RouteRecordRaw {
  const children = [];

  const routeName = `core-${coreNode.core.slug}`;

  if (coreNode.core.iframe) {
    children.push({
      name: routeName,
      path: '',
      component: () => import('@/components/cores/IFrameView.vue'),
      props: () => ({
        iframe: coreNode.core.iframe,
      }),
    });
  } else {
    children.push({
      name: routeName,
      path: '',
      component: () => import('@/components/cores/CoresApp.vue'),
      props: () => ({
        coreNode: coreNode,
      }),
    });
  }

  children.push(...coreNode.children.flatMap((pageNode) => createLanguagePageRoutes(pageNode)));

  return {
    path: coreNode.core.slug,
    meta: {
      coreNode: coreNode,
    },
    children: children,
  };
}

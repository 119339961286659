<template>
  <SlideInLayout
    ref="layout"
    data-testid="feedback.slide-in.container"
    :open="model"
    @close="close"
    @submit="submitFeedback"
  >
    <template #header>{{ translateText('Give Feedback to Ekco') }}</template>
    <div class="relative d-flex flex-column mb-3">
      <label class="form-label">
        {{ translateText('Would you like to report a bug, raise a ticket, or send feedback?') }}
        <span class="text-danger">*</span>
      </label>
      <BootstrapRadio
        id="bug"
        v-model="type"
        value="bug"
        :label="translateText('Report a bug')"
        name="type"
        required
        data-testid="feedback.form.radio.report-a-bug"
      />
      <BootstrapRadio
        id="feature"
        v-model="type"
        value="feature"
        :label="translateText('Request a feature')"
        name="type"
        required
        data-testid="feedback.form.radio.request-a-feature"
      />
      <BootstrapRadio
        id="feedback"
        v-model="type"
        value="feedback"
        :label="translateText('Provide Feedback')"
        name="type"
        required
        data-testid="feedback.form.radio.provides-feedback"
      />
    </div>
    <HappinessRating v-model="rating" class="mb-3" />
    <BootstrapFormControl
      v-model="feedback"
      :name="translateText('Summarise your feedback')"
      :max-length="500"
      :placeholder="translateText('Start typing..')"
      class="mb-3"
      textarea
      required
    />
    <BootstrapAlert variant="primary" data-testid="feedback.form.alert.message">
      <VueFeather type="info" class="bi flex-shrink-0 me-3" />
      <p class="m-0">
        {{ translateText('Please contact support if you require further assistance.') }}
      </p>
    </BootstrapAlert>
    <template #buttons>
      <BootstrapButton
        name="submit-button"
        class="me-2"
        type="submit"
        :disabled="loading"
        :loading="loading"
        data-testid="feedback.form.button.send"
        @click.stop
      >
        {{ translateText('Send to Ekco') }}
      </BootstrapButton>
      <BootstrapButton
        name="cancel-button"
        variant="secondary"
        data-testid="feedback.form.button.cancel"
        @click="cancel"
      >
        {{ translateText('Cancel') }}
      </BootstrapButton>
    </template>
  </SlideInLayout>
</template>
<script setup>
import translate from '@/translate';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const routeLocation = useRoute();
const userStore = useUserStore();
const customersStore = useCustomersStore();
const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);
const feedback = ref('');
const rating = ref('');
const type = ref('');
const loading = ref(false);
const route = computed(() => routeLocation.path);

const submitFeedback = async () => {
  const key = userStore.user.USER_FEEDBACK_API_KEY;
  /**
   * CORS error, Azure blocks responses to localhost.
   * However, the request does go through to the function.
   */
  try {
    loading.value = true;
    await axios({
      method: 'post',
      url: `https://ekco-receive-feedback.azurewebsites.net/api/user_feedback?code=${key}`,
      headers: {
        'Content-Type': 'text/plain',
      },
      data: JSON.stringify({
        eventTime: new Date().toUTCString(),
        id: uuidv4(),
        rating: rating.value,
        type: type.value,
        feedback: feedback.value,
        route: route.value,
        profile: userStore.user,
        customer: customersStore.selectedCustomer,
      }),
    });
  } finally {
    model.value = false;
    loading.value = false;
    rating.value = '';
    feedback.value = '';
    type.value = '';
  }
};

const translateText = (input) => translate.translate(language.value, input);

const close = () => (model.value = false);
const cancel = () => {
  model.value = false;
  loading.value = false;
  rating.value = '';
  feedback.value = '';
  type.value = '';
};
</script>

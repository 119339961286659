<template>
  <button
    :id="id"
    :class="[
      'btn btn-icon d-flex align-items-center',
      {'icon-button-hover-focus': props.hoverable},
      `btn-${variant} btn-${size}`,
    ]"
    :disabled="disabled"
    type="button"
  >
    <Transition mode="out-in">
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
      <span v-else class="d-flex align-items-center">
        <VueFeather :type="props.iconName" :size="getSize(props.size)" />
      </span>
    </Transition>
  </button>
</template>

<script lang="ts" setup>
const props = defineProps({
  variant: {
    type: String,
    default: 'ghost',
    validators: (value: string) => ['primary', 'secondary', 'ghost'].includes(value),
  },
  iconName: {
    type: String,
    default: 'plus',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'sm',
  },
  hoverable: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const getSize = (size: string) => {
  switch (size) {
    case 'xs':
      return 14;
    case 'sm':
      return 16;
    case 'md':
      return 20;
    case 'lg':
      return 24;
    case 'xl':
      return 28;
    case 'xx':
      return 32;
    default:
      return 16;
  }
};
</script>

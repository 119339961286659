<template>
  <div class="dropdown">
    <button
      type="button"
      class="btn btn-ghost dropdown-toggle d-flex align-items-center p-2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
    >
      <span class="d-flex align-items-center me-0s me-lg-2">
        <img
          class="flex-shrink-0 rounded-5 me-2"
          src="@/assets/images/user/avatar.png"
          style="width: 32px; height: 32px"
          alt="Profile image"
        />
        <div class="d-none d-lg-flex flex-column align-items-start">
          <span class="text-nowrap" data-hj-suppress>{{ name }}</span>
          <p
            class="d-inline-block text-truncate mb-0 font-roboto"
            style="max-width: 150px"
            data-hj-suppress
          >
            {{ selectedCust?.name }}
          </p>
        </div>
      </span>
    </button>
    <form class="dropdown-menu p-4">
      <div id="profile-menu-user" class="mb-3">
        <router-link
          to="/team"
          class="submenu-title d-flex h-100 align-items-center"
          router-link-exact-active
        >
          <img
            class="rounded-5 me-2"
            src="@/assets/images/user/avatar.png"
            style="width: 30px; height: 30px"
            alt="Profile image"
          />
          <div class="d-flex flex-column">
            <span data-hj-suppress>{{ name }}</span>
            <span data-hj-suppress style="font-size: 0.65rem" class="text-uppercase">
              {{ username }}
            </span>
          </div>
        </router-link>
      </div>
      <CustomerMultiSelectSearch id="profile-menu-customer-select" class="mb-3" />
      <div id="profile-menu-language-select" class="mb-3">
        <LanguageSelect class="w-100" />
      </div>
      <div id="profile-menu-logout">
        <a class="d-flex align-items-center" @click="logout">
          <VueFeather type="log-in" class="me-2"></VueFeather>
          <span>Log out</span>
        </a>
        <a
          v-if="isStaff"
          class="d-flex align-items-center pt-3"
          target="_blank"
          :href="backendAdmin"
        >
          <VueFeather type="external-link" class="me-2"></VueFeather>
          <span>Admin</span>
        </a>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import config from '@/config';

const customerStore = useCustomersStore();
const userStore = useUserStore();
const msal = useMsal();

const username = computed(() => userStore.msalToken?.account?.username);
const name = computed(() => userStore.msalToken?.account?.name);
const selectedCust = computed(() => {
  return customerStore.selectedCustomer?.customer;
});
const isStaff = computed(() => userStore.user?.is_staff);
const backendAdmin = computed(() => userStore.user?.BACKEND_ADMIN);

const logout = async () => {
  await msal.instance.logoutRedirect({
    account: msal.instance.getAllAccounts()[0],
    postLogoutRedirectUri: config.REDIRECT_URL,
  });
};
</script>
<style lang="scss" scoped>
a {
  // As per the designs
  color: var(--bs-body-color);
}

.dropdown-toggle::after {
  transition: all 0.2s ease;
  transform: rotate(0deg);
}

.dropdown-toggle.show::after {
  transform: rotate(180deg);
}

.dropdown-menu {
  border-width: 0;
  box-shadow: var(--bs-dropdown-box-shadow);
  min-width: 303px;
}
</style>

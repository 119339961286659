export type SavedLayoutItem = {
  h: number;
  w: number;
  x: number;
  y: number;
  core?: {id: number};
  name: string;
  moved: boolean;
  static: boolean;
};

export type UserSettings = {
  user_id: number | undefined; // Only undefined during app-init/not logged in
  layout: SavedLayoutItem[];
  theme: string;
  language: string;
  class_name: string;
};

function userSettingsDefault(): UserSettings {
  return {
    language: 'en-US',
    theme: 'light-only',
    user_id: undefined,
    layout: [],
    class_name: 'UserSettings',
  };
}

export const useUserSettingsStore = defineStore('userSettings', () => {
  const {setItem, getItem} = useLocalStorage();

  const userSettings = reactive(getItem<UserSettings>('userSettings') || userSettingsDefault());
  const loaded = ref(userSettings.user_id !== undefined);

  const applyUserSettings = () => {
    setItem('userSettings', userSettings);
    document.body.className = userSettings.theme;
  };

  const replaceUserSettings = (newUserSettings: UserSettings) => {
    Object.assign(userSettings, newUserSettings);
    loaded.value = true;
    applyUserSettings();
  };

  const setUserSettingsTheme = (theme: string) => {
    Object.assign(userSettings, {theme});
    applyUserSettings();
  };

  const setUserSettingsLanguage = (language: string) => {
    Object.assign(userSettings, {language});
    applyUserSettings();
  };

  const setUserSettingsLayout = (layout: SavedLayoutItem[]) => {
    Object.assign(userSettings, {layout});
    applyUserSettings();
  };

  /** Retrieve the user settings again from the backend */
  const forceRefreshUserSettings = async () => {
    const {data} = await apiGetUserSettings();
    replaceUserSettings(data);
  };

  const saveUserSettings = async () => {
    const response = await apiPutUserSettings(userSettings);
    const data: UserSettings = response.data;
    Object.assign(userSettings, data);
    applyUserSettings();
  };

  return {
    userSettings,
    loaded,
    replaceUserSettings,
    setUserSettingsTheme,
    setUserSettingsLanguage,
    setUserSettingsLayout,
    forceRefreshUserSettings,
    saveUserSettings,
  };
});

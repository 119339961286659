<template>
  <div class="dropdown" @click="clearSearch()" ref="dropdownElement">
    <slot name="toggle"></slot>
    <div class="dropdown-menu" data-testid="dropdown.menu">
      <input
        v-if="props.searchable !== null"
        :id="searchable"
        v-model="searchInput"
        type="text"
        class="form-control mx-2"
        placeholder="Search"
        aria-label="Search"
        style="width: auto !important"
        data-testid="dropdown-select.search.input"
        @input="search($event)"
      />
      <div
        class="custom-scrollbar h-100"
        style="overflow-y: auto"
        data-testid="dropdown-select.options.list"
      >
        <slot name="items"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import Dropdown from 'bootstrap/js/dist/dropdown.js';

const props = defineProps({
  searchable: {
    type: String,
    default: null,
  },
});

const dropdownElement = ref(null);
const searchInput = ref('');

onMounted(() => {
  if (dropdownElement.value) {
    new Dropdown(dropdownElement.value);
  }
});

// search function for dropdown items
const search = (e) => {
  const input = e.target.value.toLowerCase();
  const items = dropdownElement.value.querySelectorAll('.dropdown-item');
  items.forEach((item) => {
    if (item.textContent.toLowerCase().indexOf(input) > -1) {
      item.classList.remove('d-none');
    } else {
      item.classList.add('d-none');
    }
  });
};

// if dropdown is toggled clear search input and show all items
const clearSearch = () => {
  if (searchInput.value !== '') {
    searchInput.value = '';
    search({target: searchInput});
  }
};
</script>

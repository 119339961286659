export type Content = {
  category: string;
  class_name: string;
  context_message: string;
  description: string;
  kwargs: any;
  language: string;
  name: string;
  private: boolean;
  title: string;
  type: string;
  url: string;
  path: string; // WidgetEmbedding URL path, see WidgetSerializer in BE
  w: number;
  h: number;
};

export type Embedding = {
  class_name: string;
  content: Content;
  order: number;
};

export type Iframe = {
  auth_scopes: string;
  class_name: string;
  url: string;
};

export type Core = {
  class_name: string;
  description: string;
  icon: string;
  id: number;
  iframe: Iframe | null;
  nav_type: string; // Missing for children
  order: number;
  slug: string;
  title: string;
};

/**
 * Temporarily named for the `NavigationViewSet`.
 * This will change when other dashboards are introduced via permissions.
 * @see {@link apps/portal_navigation/api/views.py}
 */
export type NavigationViewSet = {
  home_core_slug: string;
  cores: Core[];
  pages: Page[];
};

export type Page = {
  class_name: string;
  embeddings: Embedding[];
  icon: string;
  id: number;
  core_id?: number;
  order: number;
  page_layout: string;
  parent_id: number | null;
  route: string | null;
  title: string;
  title_en: string | null;
  title_nl: string | null;
  url: string;
};

export type TreeNodeCore = {
  core: Core;
  collapsed: boolean;
  children: TreeNodePage[];
  node_type: 'CoreNode';
};
export type TreeNodePage = {
  page: Page;
  collapsed: boolean;
  children: TreeNodePage[];
  parent: TreeNodeCore | TreeNodePage;
  node_type: 'PageNode';
};
export type TreeNav = TreeNodeCore[];

function makeCoreNode(core: Core): TreeNodeCore {
  return {
    core: core,
    collapsed: true,
    children: [],
    node_type: 'CoreNode',
  };
}

function makePageNode(page: Page, parent: TreeNodeCore | TreeNodePage): TreeNodePage {
  return {
    page: page,
    collapsed: true,
    children: [],
    parent: parent,
    node_type: 'PageNode',
  };
}

function makePageNodeRecursive(
  page: Page,
  parent: TreeNodeCore | TreeNodePage,
  childrenMap: Map<number, Page[]>,
): TreeNodePage {
  const pageNode = makePageNode(page, parent);
  const childPages = childrenMap.get(page.id) ?? [];
  pageNode.children = childPages.map((p) => makePageNodeRecursive(p, pageNode, childrenMap));
  return pageNode;
}

export function makeCoreNavigationTree(cores: Core[], pages: Page[]): TreeNav {
  // Fast lookup of pages that have a certain parent_id (i.e. are a child of)
  const childLookup: Map<number, Page[]> = new Map();
  for (const page of pages) {
    if (page.parent_id) {
      let pageList = childLookup.get(page.parent_id);
      if (!pageList) {
        pageList = [];
        childLookup.set(page.parent_id, pageList);
      }
      pageList.push(page);
    }
  }

  const coreNodes = cores.map((core) => {
    const coreNode = makeCoreNode(core);
    const directCorePages = pages.filter((p) => !p.parent_id && p.core_id === core.id);
    coreNode.children = directCorePages.map((p) => makePageNodeRecursive(p, coreNode, childLookup));
    return coreNode;
  });

  return coreNodes;
}

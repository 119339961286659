<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="20"
    height="20"
    viewBox="0 0 26.4 23.1"
    style="enable-background: new 0 0 26.4 23.1"
    xml:space="preserve"
  >
    <g id="review-feedback" transform="translate(16.625 15.039)">
      <g id="Group_1178" transform="translate(-16.625 -15.039)">
        <g id="Group_1177" transform="translate(5.539 5.64)">
          <path
            id="Path_1066"
            class="st0"
            d="M12.2,0H0.5C0.2,0,0,0.2,0,0.5c0,0.2,0.2,0.5,0.5,0.5h11.8c0.3,0,0.5-0.2,0.5-0.5
          S12.5,0,12.2,0C12.2,0,12.2,0,12.2,0z"
          />
          <path
            id="Path_1067"
            class="st0"
            d="M10.8,3.1H0.5C0.2,3.1,0,3.3,0,3.6C0,3.8,0.2,4,0.5,4h10.4c0.3,0,0.5-0.2,0.5-0.5
          S11.1,3.1,10.8,3.1C10.8,3.1,10.8,3.1,10.8,3.1z"
          />
          <path
            id="Path_1068"
            class="st0"
            d="M6.9,6.5H0.6c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5c0,0,0,0,0,0h6.3c0.3,0,0.5-0.2,0.5-0.5
          S7.1,6.5,6.9,6.5C6.9,6.5,6.9,6.5,6.9,6.5z"
          />
        </g>
        <path
          id="Path_1069"
          class="st1"
          d="M18.6,12l6.6-6.7c0.5-0.7,0.4-1.6-0.2-2.1c-0.6-0.5-1.5-0.5-2.1,0.1l-6.5,6.5l-0.6,2.7
        L18.6,12z"
        />
        <path
          id="Path_1070"
          class="st0"
          d="M22.8,7.8v8c0,0.7-0.5,1.2-1.2,1.2H6.4l-3.2,3.2c-0.1,0.1-0.2,0.2-0.3,0.3
        c-0.4,0.4-1.4,1.4-1.6,1.4c0,0-0.1,0-0.1-0.1V2.4c0-0.7,0.5-1.2,1.2-1.2h19.1c0.7,0,1.2,0.5,1.2,1.2v1L23,3.2
        c0.3-0.2,0.6-0.4,1-0.5V2.4C24,1.1,22.9,0,21.6,0H2.4C1.1,0,0,1.1,0,2.4v19.4c0,0.7,0.5,1.3,1.2,1.3c0,0,0,0,0,0
        c0.6,0,1.1-0.3,2.5-1.8C3.9,21.2,4,21.1,4,21l2.8-2.8h14.7c1.3,0,2.4-1.1,2.4-2.4V6.6L22.8,7.8z"
        />
        <line id="Line_67" class="st1" x1="21.7" y1="4.5" x2="23.8" y2="6.5" />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.st0 {
  fill: currentColor;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.44;
}
</style>

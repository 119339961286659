<template>
  <router-link v-slot="slotProps" :to="{name: `page-${page.id}-${lang}`}" :custom="custom">
    <slot v-bind="slotProps" />
  </router-link>
</template>
<script setup lang="ts">
import {Page} from '@/stores/apolloPlatform/cores/portalNavigation';

defineProps<{page: Page; custom?: boolean}>();

const userSettingsStore = useUserSettingsStore();
const lang = computed(() => userSettingsStore.userSettings.language.substring(0, 2));
</script>

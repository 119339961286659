<template>
  <div class="relative d-flex flex-column">
    <label v-if="!onlyInput" :for="name" class="form-label">
      {{ name }}
      <span v-if="required === true" class="text-danger">*</span>
    </label>
    <div :class="{'input-group has-validation': inputGroup}">
      <textarea
        v-if="textarea === true"
        :id="id"
        v-model="model"
        :aria-label="ariaLabel || name"
        autocomplete="autocomplete"
        class="form-control"
        :class="[
          {'is-invalid': error.length > 0 && serverError === true},
          {'is-valid': error.length === 0 && serverError === true},
        ]"
        :placeholder="placeholder"
        :aria-required="required"
        :required="required"
        :maxlength="maxLength"
        rows="5"
        :disabled="disabled"
        data-testid="form-control.text-area"
      />
      <input
        v-else
        :id="id"
        v-model="model"
        :aria-label="ariaLabel || name"
        :autocomplete="autocomplete ? 'on' : 'off'"
        class="form-control"
        :class="[
          {'is-invalid': error.length > 0 && serverError === true},
          {'is-valid': error.length === 0 && serverError === true},
          size ? `form-control-${size}` : '',
        ]"
        :placeholder="placeholder"
        :aria-required="required"
        :required="required"
        :maxlength="maxLength"
        :aria-describedby="`${name}-button-addon`"
        :disabled="disabled"
        :data-testid="id"
        @keydown.enter.prevent="$emit('enter')"
      />
      <BootstrapButton
        v-if="inputGroup"
        :id="`${id}-button-addon`"
        variant="secondary"
        :name="`${name}-button-addon`"
        :outlined="true"
        class="border-start-0"
        @click="$emit('addon-clicked')"
      >
        <slot name="buttonContent" />
      </BootstrapButton>
      <span
        role="alert"
        aria-atomic="true"
        class="text-danger invalid-feedback"
        :data-testid="
          textarea === true
            ? 'form-control.text-area.error-message'
            : 'form-control.input.error-message'
        "
      >
        {{ error.length > 0 ? error : `${name} is required` }}
      </span>
    </div>
    <small
      v-if="maxLength"
      :id="name"
      class="text-muted mt-1"
      :data-testid="maxLength && 'form-control.text-area.max-char' + maxLength"
    >
      {{ model.length }} / {{ maxLength }}
    </small>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  name: string;
  placeholder?: string;
  modelValue: string;
  ariaLabel?: string;
  error?: string;
  required?: boolean;
  textarea?: boolean;
  maxLength?: number;
  onlyInput?: boolean;
  disabled?: boolean;
  serverError?: boolean;
  autocomplete?: boolean;
  size?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  ariaLabel: '',
  error: '',
  required: false,
  textarea: false,
  maxLength: undefined,
  onlyInput: false,
  disabled: false,
  serverError: false,
  autocomplete: true,
  size: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  'onUpdate:modelValue': [value: string]; // Required for vnodes, i.e h() function
  enter: [value: void];
  'addon-clicked': [value: void];
}>();

/**
 * "The return type is currently ignored and can be any, but we may
 * leverage it for slot content checking in the future."
 * @see {@link https://vuejs.org/api/sfc-script-setup.html#defineslots}
 */
const slots = defineSlots<{
  buttonContent?: unknown;
}>();

const model = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    emit('update:modelValue', value);
    emit('onUpdate:modelValue', value);
  },
});
const inputGroup = computed(() => props.textarea === false && slots.buttonContent);

const id = computed(() =>
  props.name
    ?.toLowerCase()
    .replace(/\s/g, '')
    .replace(/\([^()]*\)/g, ''),
);
</script>
<style lang="scss" scoped>
:deep(.input-group) > .btn-outline-secondary {
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}
</style>

<template>
  <DropdownSelect v-if="language">
    <template #toggle>
      <BootstrapButton
        name="language-select"
        :variant="theme === 'light-only' ? 'light' : 'dark'"
        class="dropdown-toggle text-wrap w-100"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <em class="flag-icon me-1" :class="selectedLanguage.icon"></em>
        <span class="name">{{ selectedLanguage.name }}</span>
      </BootstrapButton>
    </template>
    <template #items>
      <button
        v-for="(lang, index) in items"
        :id="lang.name.toLowerCase()"
        :key="index"
        class="dropdown-item text-wrap w-100"
        @click.prevent="language !== lang.code ? changeLocale(lang.code) : ''"
      >
        <em class="flag-icon" :class="lang.icon"></em>
        {{ lang.name }}
      </button>
    </template>
  </DropdownSelect>
</template>

<script setup>
import {ref, computed} from 'vue';
import BootstrapButton from '@/components/shared/bootstrap/BootstrapButton/BootstrapButton.vue';
import DropdownSelect from '@/components/shared/atoms/DropdownSelect';

const widgetStore = useWidgetStore();
const coresStore = useCoresStore();
const userSettingsStore = useUserSettingsStore();

const items = ref([
  {
    name: 'English',
    icon: 'flag-icon-gb',
    code: 'en-US',
  },
  {
    name: 'Nederlands',
    icon: 'flag-icon-nl',
    code: 'nl-NL',
  },
]);

const language = computed(() => userSettingsStore.userSettings.language);
const selectedLanguage = computed(
  () => items.value.find(({code}) => code === language.value) || {},
);
const theme = computed(() => userSettingsStore.userSettings.theme);

const changeLocale = async (language) => {
  widgetStore.setLoadedLayout(false);
  userSettingsStore.setUserSettingsLanguage(language);
  await userSettingsStore.saveUserSettings();
  await coresStore.switchLanguage();
  // News reload is handled by MainPage.vue
};
</script>

<template>
  <div id="default-layout" class="d-flex flex-column h-100 overflow-hidden">
    <slot name="header" />

    <!-- Content area -->
    <div id="content-area" class="d-flex flex-grow-1 overflow-hidden">
      <slot name="sidebar" />
      <main id="scrolling" class="flex-grow-1 overflow-x-hidden overflow-auto">
        <slot name="main" />
      </main>
    </div>
  </div>
</template>

<template>
  <div class="mode d-flex align-items-center">
    <em
      v-if="theme === 'light-only'"
      class="fa fa-moon-o w-100 h-100 p-3 d-flex align-items-center"
      @click="setLayout('dark-only')"
    ></em>
    <em
      v-if="theme === 'dark-only'"
      class="fa fa-sun-o w-100 h-100 p-3 d-flex align-items-center"
      @click="setLayout('light-only')"
    ></em>
  </div>
</template>

<script>
import {mapState, mapActions} from 'pinia';

export default {
  name: 'ThemeMode',
  data() {
    return {};
  },
  computed: {
    ...mapState(useUserSettingsStore, ['userSettings']),
    theme() {
      return this.userSettings.theme;
    },
  },
  methods: {
    ...mapActions(useUserSettingsStore, ['saveUserSettings', 'setUserSettingsTheme']),
    async setLayout(theme) {
      this.setUserSettingsTheme(theme);
      await this.saveUserSettings();
    },
  },
};
</script>

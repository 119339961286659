/**
 * Returns a CSS custom property of the html element.
 *
 * @param property - CSS custom property, e.g. "--bs-blue"
 * @returns the property's value, e.g. "#0d6efd" or undefined
 */
export const getCssProperty = (property: string): string | undefined => {
  const html = document.querySelector('html') as HTMLHtmlElement; // html tag should always exist
  const propertyValue = window.getComputedStyle(html).getPropertyValue(property);
  return propertyValue !== '' ? propertyValue : undefined;
};

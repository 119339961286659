import qs from 'qs';
import appConfig from '@/config';
import {InternalAxiosRequestConfig} from 'axios';

/**
 * Apollo platform Axios instance
 */
const apolloPlatformAxiosInstance = createAxiosInstance({
  baseURL: appConfig.BACKEND_API,
  paramsSerializer: {
    serialize: (params: any) => qs.stringify(params, {encode: false}),
  },
});

/**
 * Interceptor used by each Axios instance
 */
apolloPlatformAxiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const userStore = useUserStore();
    const userSettingsStore = useUserSettingsStore();
    const customersStore = useCustomersStore();
    if (userStore.msalToken !== null && userStore.msalToken.accessToken) {
      config.headers.Authorization = `Bearer ${userStore.msalToken.accessToken}`;
    }
    if (customersStore.selectedCustomer !== null) {
      config.headers['Sphere-Customer-UID'] =
        customersStore.selectedCustomer.customer.debtor_number;
    }
    config.headers['Accept-Language'] = userSettingsStore.userSettings.language;
    return config;
  },
  (error: unknown) => Promise.reject(error),
);

export {apolloPlatformAxiosInstance};

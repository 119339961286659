import {getCssProperty} from '@/sphere/device';
// https://getbootstrap.com/docs/5.3/layout/breakpoints/#available-breakpoints

export function useScreenSize() {
  const screenWidth = ref(window.innerWidth);
  const bsBreakpointLg = getCssProperty('--bs-breakpoint-lg'); // 992px
  const desktopScreenWidth = bsBreakpointLg!.replace('px', '');
  const isMobile = computed(() => screenWidth.value < Number(desktopScreenWidth));

  function update() {
    screenWidth.value = window.innerWidth;
  }

  onMounted(() => window.addEventListener('resize', update));
  onUnmounted(() => window.removeEventListener('resize', update));

  return {screenWidth, isMobile};
}
